export let TAHUN_DEFAULT = 2022;

export const setTahunDefault = (tahun: number) => {
	TAHUN_DEFAULT = tahun;
};

export const BULAN_DEFAULT = 10;

export const isLatihan =
	process.env.NEXT_PUBLIC_EDM_VERSION?.endsWith('LATIHAN');

export const deploymentVersion = process.env.NEXT_PUBLIC_EDM_VERSION;

export const isStaging = deploymentVersion?.toLowerCase().includes('staging');
export const isProduction = deploymentVersion
	?.toLowerCase()
	.includes('production');

export const sentryEnvironment = isProduction
	? 'production'
	: isStaging
	? 'staging'
	: 'development';
