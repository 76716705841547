import { setUser } from '@sentry/nextjs';

export const setSentryUser = ({
	nik,
	role,
	nsm,
	email,
	kodeKabupaten,
	kodeProvinsi,
}: {
	nik: string;
	role: string;
	email: string;
	nsm?: string;
	kodeKabupaten?: string;
	kodeProvinsi?: string;
}) => {
	setUser({
		nik,
		role,
		nsm,
		email,
		kodeKabupaten,
		kodeProvinsi,
	});
};
