import { ChakraProvider } from '@chakra-ui/react';
import {
	DehydratedState,
	Hydrate,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { NextPageContext } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
// import { useRouter } from 'next/router';
import Script from 'next/script';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import React from 'react';

import '@fontsource/inter/variable-full.css';

import AuthWrapper from '@/lib/auth/AuthWrapper';
import { deploymentVersion } from '@/lib/constants';
import Environment from '@/lib/environment';
import { useClearQueriesOnLogin } from '@/hooks/useClearQueriesOnLogin';
import { useNotifyBrowserUpdates } from '@/hooks/useNotifyBrowserUpdates';

import { NextPageWithLayout } from '@/pages/page';
import { theme } from '@/theme';

const NoNetworkAlertNoSSR = dynamic(
	() => import('@/components/general/NoNetworkAlert'),
	{ ssr: false }
);

type PageProps = {
	dehydratedState?: DehydratedState;
	session?: Session;
};

type AppPropsWithLayout<P = {}> = {
	err?: NextPageContext['err'];
	Component: NextPageWithLayout;
} & AppProps<P>;

const AppHead = () => {
	return (
		<Head>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/*  @ts-ignore */}
			<meta name="google" content="notranslate" />
			<link rel="shortcut icon" type="image/png" href="/favicon/favicon.png" />
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href="/favicon/apple-touch-icon.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href="/favicon/favicon-32x32.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href="/favicon/favicon-16x16.png"
			/>
		</Head>
	);
};

// const GoogleAnalytics: React.FC = () => {
// 	const GA_MEASUREMENT_ID =
// 		process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID ?? '';

// 	const router = useRouter();
// 	//track page view on page change
// 	React.useEffect(() => {
// 		if (!window.gtag || !GA_MEASUREMENT_ID) {
// 			return;
// 		}

// 		const handleRouteChange = (url: string) => {
// 			//you may notice there's a type error here: Cannot find name 'gtag'
// 			//We will fix this in step 3.
// 			gtag?.('config', GA_MEASUREMENT_ID, {
// 				page_path: url,
// 			});
// 		};
// 		router.events.on('routeChangeComplete', handleRouteChange);
// 		router.events.on('hashChangeComplete', handleRouteChange);
// 		return () => {
// 			router.events.off('routeChangeComplete', handleRouteChange);
// 			router.events.off('hashChangeComplete', handleRouteChange);
// 		};
// 	}, [GA_MEASUREMENT_ID, router.events]);

// 	if (!GA_MEASUREMENT_ID) {
// 		// eslint-disable-next-line no-console
// 		console.log('No Google Analytics ID found');
// 		return null;
// 	}

// 	return (
// 		<>
// 			<Script
// 				strategy="lazyOnload"
// 				src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
// 			/>

// 			<Script strategy="lazyOnload" id="google-tag-script">
// 				{`
// 				window.dataLayer = window.dataLayer || [];
// 				function gtag(){dataLayer.push(arguments);}
// 				gtag('js', new Date());
// 				gtag('config', '${GA_MEASUREMENT_ID}', {
// 				page_path: window.location.pathname,
// 				});
// 		`}
// 			</Script>
// 		</>
// 	);
// };

type ChatbotOptions = {
	CHATBOT_API_URL?: string;
	CHATBOT_API_KEY: string;
	CHATBOT_APP_NAME: string;
	CHATBOT_WIDGET_STYLE?: {
		position?: string;
		top?: string;
		right?: string;
		bottom?: string;
		left?: string;
	};
};

const ChatWidget: React.FC = () => {
	const stagingChatbotOptions: ChatbotOptions = {
		CHATBOT_API_URL: 'https://staging-be.chatbot.kemenag.go.id',
		CHATBOT_API_KEY: 'f8154915-d73b-4472-9ff8-d787b1295fb2',
		CHATBOT_APP_NAME: 'dummytester',
		CHATBOT_WIDGET_STYLE: {
			position: 'fixed',
			bottom: '1rem',
			right: '1rem',
		},
	};

	const productionChatbotOptions: ChatbotOptions = {
		CHATBOT_API_KEY: '3f967926-09c6-44f9-8eec-0f4ca13e3613',
		CHATBOT_APP_NAME: 'evaluasi-diri-madrasah',
	};

	let chatbotOptions: ChatbotOptions = stagingChatbotOptions;

	// set the chatbot options based on the deployment version
	if (deploymentVersion?.toLowerCase().includes('production')) {
		chatbotOptions = productionChatbotOptions;
	}

	return (
		<>
			<Script
				src="https://widget.chatbot.madrasahkemenag.com/chatbot-chat-widget.js"
				strategy="lazyOnload"
			/>
			<Script strategy="lazyOnload" id="chatbot-chat-widget-options-script">
				{`
							window.CHATBOT_OPTIONS = ${JSON.stringify(chatbotOptions)};
					`}
			</Script>
			<style jsx global>
				{`
					.tw-9v2sxo {
						bottom: 0.5rem !important;
						z-index: 1400;
					}
				`}
			</style>
		</>
	);
};

function MyApp({
	Component,
	pageProps: { session, ...pageProps },
}: AppPropsWithLayout<PageProps>) {
	// Use the layout defined at the page level, if available
	const getLayout = Component.getLayout || ((page) => page);

	const [queryClient] = React.useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnWindowFocus: false,
						refetchOnReconnect: false,
					},
				},
			})
	);

	useClearQueriesOnLogin({ queryClient });
	useNotifyBrowserUpdates();

	return (
		<>
			<AppHead />
			<ChatWidget />
			<QueryClientProvider client={queryClient}>
				<Hydrate state={pageProps.dehydratedState}>
					<SessionProvider session={session}>
						<JotaiProvider>
							<ChakraProvider theme={theme}>
								<NoNetworkAlertNoSSR />
								<AuthWrapper>
									<Environment>
										{getLayout(<Component {...pageProps} />)}
									</Environment>
								</AuthWrapper>
							</ChakraProvider>
						</JotaiProvider>
					</SessionProvider>
				</Hydrate>
				<ReactQueryDevtools position="top-left" />
			</QueryClientProvider>
		</>
	);
}

// MyApp.getInitialProps = async (context: AppContext) => {
// 	if (context?.ctx?.res) {
// 		context.ctx.res?.setHeader(
// 			'Cache-Control',
// 			'public, s-maxage=10, stale-while-revalidate=59'
// 		);
// 	}

// 	const appProps = await App.getInitialProps(context);

// 	// get session on server side
// 	if (typeof window === 'undefined') {
// 		try {
// 			const session = await getSession(context.ctx);

// 			return {
// 				...appProps,
// 				pageProps: {
// 					...appProps.pageProps,
// 					session,
// 				},
// 			};
// 		} catch (error) {
// 			console.error(error);

// 			return {
// 				...appProps,
// 				pageProps: {
// 					...appProps.pageProps,
// 					session: null,
// 				},
// 			};
// 		}
// 	}

// 	return {
// 		...appProps,
// 	};
// };

export default MyApp;
