import { UseToastOptions } from '@chakra-ui/react';

export const getSuccessToastConfigs = (
	options: UseToastOptions
): UseToastOptions => ({
	status: 'success',
	duration: 3000,
	isClosable: true,
	position: 'top-right',
	...options,
});

export const getFailToastConfigs = (
	options: UseToastOptions
): UseToastOptions => ({
	status: 'error',
	duration: 8000,
	isClosable: true,
	position: 'top-right',
	...options,
});

export const getWarningToastConfigs = (
	options: UseToastOptions
): UseToastOptions => ({
	status: 'warning',
	duration: 5000,
	isClosable: true,
	position: 'top-right',
	...options,
});
