import { Box, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import * as React from 'react';

import { checkRoles, rolesRouteMap } from '@/lib/auth/allowedRoles';
import { setSentryUser } from '@/lib/sentry/setSentryUser';

import { apiClientEDM, setClientToken } from '@/http/api-client';

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { data: sessionData, status } = useSession();
	const router = useRouter();

	const defaultUserRole = sessionData?.defaultUserRole;
	const token = sessionData?.token ?? '';
	const roleMapToCheck = rolesRouteMap.find((item) =>
		router.pathname.startsWith(item.path)
	);

	const pathname = router.pathname;

	React.useEffect(() => {
		if (
			!!defaultUserRole &&
			!!roleMapToCheck &&
			!checkRoles({ defaultUserRole, rolesToCheck: roleMapToCheck.roles })
		) {
			router.replace('/login');
		}
	}, [defaultUserRole, roleMapToCheck, router]);

	React.useEffect(() => {
		const rerouteToLogin = async () => {
			// eslint-disable-next-line no-console
			console.log('=== UNAUTHENTICATED ===, redirecting to /login');
			await router.push('/login');
		};

		if (
			status === 'unauthenticated' &&
			![
				'/',
				'/login',
				'/dalam-pengembagan',
				'/detail-panduan',
				'/halaman-panduan',
			].includes(pathname)
		) {
			rerouteToLogin();
		}
	}, [pathname, router, status]);

	React.useEffect(() => {
		if (sessionData) {
			const nik = sessionData?.nik ?? '';
			const nsm = sessionData?.nsm ?? '';
			const email = sessionData?.email ?? '';
			const role = sessionData?.defaultUserRole ?? '';
			const kodeKabupaten = sessionData?.kodeKabupaten ?? '';
			const kodeProvinsi = sessionData?.kodeProvinsi ?? '';

			setSentryUser({
				nik,
				role,
				email,
				nsm,
				kodeKabupaten,
				kodeProvinsi,
			});
		}
	}, [sessionData]);

	if (status !== 'loading') {
		setClientToken(apiClientEDM, token);
	}

	if (status === 'loading') {
		return (
			<Box w="100vw" h="100vh" display="grid" placeItems="center">
				<Spinner />
			</Box>
		);
	}

	return <>{children}</>;
};

export default AuthWrapper;
