import axios from 'axios';

import { ENVVars } from '@/types/ENVVars';

export const getENVVars = async () => {
	const response = await axios.get<{
		envVars: ENVVars;
	}>('/api/general/env-vars');

	return response.data.envVars;
};
