export interface Role {
	kode: UserRole;
	nama: string;
	deskripsi: string;
	group: string;
	created_by: string;
	updated_by: string;
}

// Group Role
export enum UserRole {
	Pusat = 'admin_pusat',
	Provinsi = 'admin_provinsi',
	KabKota = 'admin_kabkota',
	Pengawas = 'pengawas',
	StafMadrasah = 'staf_madrasah',
	BendaharaMadrasah = 'bendahara_madrasah',
	KepalaMadrasah = 'kepala_madrasah',
}

// Code Role
export interface CodeRole {
	code: string;
	name: string;
	groupRole: UserRole;
}

export const ListCodeRoles: Array<CodeRole> = [
	{
		code: 'admin_kabkota',
		name: 'Admin Kabupaten/Kota',
		groupRole: UserRole.KabKota,
	},
	{
		code: 'auditor_kabkota_external',
		name: 'Auditor Kabkota External',
		groupRole: UserRole.KabKota,
	},
	{
		code: 'auditor_kabkota_internal',
		name: 'Auditor Kabkota Internal',
		groupRole: UserRole.KabKota,
	},
	{
		code: 'pembuat_kebijakan_kabkota',
		name: 'Pembuat Kebijakan Kabupaten/Kota',
		groupRole: UserRole.KabKota,
	},
	{
		code: 'penanggung_jawab_teknis_kabkota',
		name: 'Penanggung Jawab Teknis Kabupaten/Kota',
		groupRole: UserRole.KabKota,
	},
	{
		code: 'penanggung_jawab_umum_kabkota',
		name: 'Penanggung Jawab Umum Kabupaten/Kota',
		groupRole: UserRole.KabKota,
	},
	{
		code: 'pengarah_kabkota',
		name: 'Pengarah Kabupaten/Kota',
		groupRole: UserRole.KabKota,
	},
	{
		code: 'bendahara_madrasah',
		name: 'Bendahara Madrasah',
		groupRole: UserRole.BendaharaMadrasah,
	},
	{
		code: 'kepala_madrasah',
		name: 'Kepala Madrasah',
		groupRole: UserRole.KepalaMadrasah,
	},
	{
		code: 'staf_madrasah',
		name: 'Staf Madrasah',
		groupRole: UserRole.StafMadrasah,
	},
	{ code: 'pengawas', name: 'Pengawas', groupRole: UserRole.Pengawas },
	{
		code: 'admin_provinsi',
		name: 'Admin Provinsi',
		groupRole: UserRole.Provinsi,
	},
	{
		code: 'auditor_provinsi_external',
		name: 'Auditor Provinsi External',
		groupRole: UserRole.Provinsi,
	},
	{
		code: 'auditor_provinsi_internal',
		name: 'Auditor Provinsi Internal',
		groupRole: UserRole.Provinsi,
	},
	{
		code: 'pembuat_kebijakan_provinsi',
		name: 'Pembuat Kebijakan Provinsi',
		groupRole: UserRole.Provinsi,
	},
	{
		code: 'penanggung_jawab_teknis_provinsi',
		name: 'Penanggung Jawab Teknis Provinsi',
		groupRole: UserRole.Provinsi,
	},
	{
		code: 'penanggung_jawab_umum_provinsi',
		name: 'Penanggung Jawab Umum Provinsi',
		groupRole: UserRole.Provinsi,
	},
	{
		code: 'pengarah_provinsi',
		name: 'Pengarah Provinsi',
		groupRole: UserRole.Provinsi,
	},
	{
		code: 'pokjawas_provinsi',
		name: 'Pokjawas Provinsi',
		groupRole: UserRole.Provinsi,
	},
	{ code: 'admin_pusat', name: 'Admin Pusat', groupRole: UserRole.Pusat },
	{
		code: 'auditor_pusat_external',
		name: 'Auditor External',
		groupRole: UserRole.Pusat,
	},
	{
		code: 'auditor_pusat_internal',
		name: 'Auditor Internal',
		groupRole: UserRole.Pusat,
	},
	{
		code: 'pembuat_kebijakan_pusat',
		name: 'Pembuat Kebijakan Pusat',
		groupRole: UserRole.Pusat,
	},
	{
		code: 'penanggung_jawab_teknis_pusat',
		name: 'Penanggung Jawab Teknis Pusat',
		groupRole: UserRole.Pusat,
	},
	{
		code: 'penanggung_jawab_umum_pusat',
		name: 'Penganggung Jawab Umum Pusat',
		groupRole: UserRole.Pusat,
	},
	{ code: 'pengarah_pusat', name: 'Pengarah Pusat', groupRole: UserRole.Pusat },
	{
		code: 'super_admin_pusat',
		name: 'Super Admin Pusat',
		groupRole: UserRole.Pusat,
	},
];
