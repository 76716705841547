import { UserRole } from '@/types/Role';

export const madrasahAllowedRoles = [
	// ...provinsiAllowedRoles,
	UserRole.BendaharaMadrasah,
	UserRole.StafMadrasah,
	// UserRole.KepalaMadrasah,
];

export const kepalaMadrasahAllowedRoles = [
	// ...provinsiAllowedRoles,
	UserRole.KepalaMadrasah,
];

export const pengawasAllowedRoles = [
	// ...pusatAllowedRoles,
	UserRole.Pengawas,
];

export const kabupatenAllowedRoles = [
	// ...pusatAllowedRoles,
	UserRole.KabKota,
];

export const provinsiAllowedRoles = [
	// ...pusatAllowedRoles,
	UserRole.Provinsi,
];

export const pusatAllowedRoles = [UserRole.Pusat];

export const checkRoles = ({
	defaultUserRole,
	rolesToCheck,
}: {
	defaultUserRole: UserRole;
	rolesToCheck: UserRole[];
}) => {
	// return !!allUserRoles.find((roleItem) => {
	// 	return rolesToCheck.find(
	// 		(roleToCheck) => roleToCheck === roleItem.role.kode
	// 	);
	// });

	return rolesToCheck.find((roleToCheck) => roleToCheck === defaultUserRole);
};

export const rolesRouteMap = [
	{ path: '/pusat', roles: pusatAllowedRoles },
	{ path: '/provinsi', roles: provinsiAllowedRoles },
	{ path: '/kabupaten', roles: kabupatenAllowedRoles },
	{ path: '/pengawas', roles: pengawasAllowedRoles },
	{ path: '/madrasah', roles: madrasahAllowedRoles },
	{ path: '/kepala-madrasah', roles: kepalaMadrasahAllowedRoles },
];
