import { Box, Spinner } from '@chakra-ui/react';
/* eslint-disable no-console */
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import { apiClientEDM } from '@/http/api-client';
import { getENVVars } from '@/http/getEnv';

export const useENVVars = () => {
	const query = useQuery(['env-vars'], () => getENVVars(), {
		staleTime: Infinity,
		cacheTime: 1000 * 60 * 60,
		onSuccess: (data) => {
			const EDM_API_URL = data.NEXT_PUBLIC_EDM_BASE_API_URL ?? '';

			apiClientEDM.defaults.baseURL = EDM_API_URL;
		},
	});

	return {
		...query,
	};
};

export interface ENVVarsProps {}

const ENVVars: React.FC<React.PropsWithChildren<ENVVarsProps>> = ({
	children,
}) => {
	const { isLoading: isLoadingEnvVars } = useENVVars();

	if (isLoadingEnvVars) {
		return (
			<Box w="100vw" h="100vh" display="grid" placeItems="center">
				<Spinner />
			</Box>
		);
	}

	return <>{children}</>;
};

export default ENVVars;
