// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react';

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
	brand: {
		900: '#1a365d',
		800: '#153e75',
		700: '#2a69ac',
	},
	green: {
		100: '#C6F6D5',
		500: '#38A169',
		900: '#1C4532',
		800: '#EFFFF3',
		700: '#F0FFF4',
	},
	border: { 200: '#c8e4cc' },
	grey: {
		600: '#F4FFF4',
	},
	tpm: {
		600: '#718096',
	},
	kemenag: {
		50: '#FAFAFA',
		100: '#F5F5F5',
		200: '#F8FEF9',
		300: '#F0FFF4',
		400: '#EFFFF3',
		500: '#006316',
		600: '#005413',
		700: '#003d0e',
		800: '#00330c',
		900: '#002a0a',
	},
};

const fonts = {
	heading: `'Inter', sans-serif`,
	body: `'Inter', sans-serif`,
};

export const theme = extendTheme({
	fonts,
	colors,
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
	},
});
